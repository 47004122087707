.aboutus{
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 80vw;
    justify-content: center;
    margin: auto;
    font-family: ShopifySans, Helvetica, Arial, Lucida Grande, sans-serif;
}

.aboutus h4{
    margin: 3vmax;
    font-size: 2vmax;
    font-weight: 600;
    text-align: center;
}

.aboutus p{
    font-size: 1.5vmax;
}

.projects{
    display: flex;
    width: 80vw;
    margin: 2vmax;
}
.projects > a > button{
    margin: 0 1vmax;
    padding: 0 3vmax;
    border-radius: 10px;
    background-color: black;
    color: white;
    transition: all 0.5s;
}

.projects > a > button:hover{
    background-color: white;
    color: black;
}